/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary: #8B5CF6;
  --primary-dark: #7C3AED;
  --background: #1E1E2D;
  --background-light: #23233A;
  --text: #FFFFFF;
  --text-secondary: #9CA3AF;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background);
  color: var(--text);
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}

.app-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  padding: 2rem;
}

.content {
  text-align: center;
  max-width: 800px;
  z-index: 10;
}

.logo-container {
  margin-bottom: 3rem;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.logo-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary);
}

.logo h1 {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #A78BFA 0%, #8B5CF6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.tagline {
  margin-bottom: 3rem;
}

.tagline h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  letter-spacing: -0.02em;
}

.coming-soon {
  display: inline-block;
  position: relative;
}

.coming-soon span {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--primary);
  position: relative;
  padding: 0 0.5rem;
}

.coming-soon::before,
.coming-soon::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40px;
  height: 1px;
  background: linear-gradient(90deg, rgba(123, 58, 237, 0) 0%, #7C3AED 100%);
}

.coming-soon::before {
  left: -50px;
}

.coming-soon::after {
  right: -50px;
  background: linear-gradient(90deg, #7C3AED 0%, rgba(123, 58, 237, 0) 100%);
}

.subscribe {
  margin-top: 3rem;
}

.subscribe p {
  font-size: 1rem;
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
}

.email-form {
  display: flex;
  justify-content: center;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
}

.email-form input {
  flex: 1;
  padding: 1rem 1.5rem;
  border-radius: 9999px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--text);
  font-family: 'Poppins', sans-serif;
  outline: none;
  transition: all 0.3s ease;
}

.email-form input:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.3);
}

.email-form button {
  padding: 1rem 1.5rem;
  border-radius: 9999px;
  border: none;
  background-color: var(--primary);
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
}

.email-form button:hover {
  background-color: var(--primary-dark);
}

@media (max-width: 768px) {
  .tagline h2 {
    font-size: 2rem;
  }
  
  .email-form {
    flex-direction: column;
  }
  
  .coming-soon::before,
  .coming-soon::after {
    width: 20px;
  }
  
  .coming-soon::before {
    left: -30px;
  }
  
  .coming-soon::after {
    right: -30px;
  }
}